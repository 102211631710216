<template>
    <div class="l-padded">
        <h3 class="t-title">
            {{ $t('settings') }}
        </h3>

        <div class="l-padded-y l-stack l-gap-2">
            <div>
                <p class="form-label">
                    {{ $t('selectUser') }}
                </p>

                <user-select
                    v-model="selectedUser"
                    :exclude="excludedUsers"
                    show-details
                ></user-select>
            </div>

            <ax-button :disabled="!selectedUser" @click="handleSubmit">
                {{ $t('simulate') }}
            </ax-button>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import AxButton from '../AxButton'
import UserSelect from '../UserSelect'

export default {
    name: 'DashboardSettingsView',
    components: {
        AxButton,
        UserSelect,
    },
    data() {
        return {
            selectedUser: null,
        }
    },
    computed: {
        ...mapState('authentication', ['userInfo']),
        ...mapGetters('authentication', ['getCurrentUserId']),
        excludedUsers() {
            return this.getCurrentUserId ? [this.getCurrentUserId] : undefined
        },
    },
    methods: {
        ...mapActions('authentication', ['simulateUser']),
        ...mapActions('common', ['loadData']),
        ...mapMutations('common', ['setDataLoading']),
        async handleSubmit() {
            this.setDataLoading(true)
            await this.simulateUser(this.selectedUser)
            this.$root.$i18n.locale = this.userInfo.language
            this.$router.push('/', this.loadData)
        },
    },
}
</script>

<i18n>
{
    "en": {
        "noOptions": "No options available",
        "noResult": "No results were found",
        "selectUser": "Select user to simulate:",
        "settings": "Settings",
        "simulate": "Simulate"
    },
    "de": {
        "noOptions": "Keine Einträge vorhanden",
        "noResult": "Keine Resultate gefunden",
        "selectUser": "Benutzer für Simulation auswählen:",
        "settings": "Einstellungen",
        "simulate": "Simulieren"
    },
    "it": {
        "noOptions": "Nessuna opzione disponibile",
        "noResult": "Non sono stati trovati risultati",
        "selectUser": "Selezionare l'utente da simulare:",
        "settings": "Impostazioni",
        "simulate": "Simulare"
    }
}
</i18n>
